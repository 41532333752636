import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from 'gatsby'

const Banner = () => {

    function SlideShow() {
    const [index, setIndex] = useState(0)
    const { allFile } = useStaticQuery(
        graphql`
        query {
          allFile(
          filter: { relativeDirectory: { eq: "slides" } }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  gatsbyImageData(
                    width: 2464
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        `
    )
    //Minus 1 for array offset from 0
    const length = allFile.edges.length - 1
    const handleNext = () =>
        index === length ? setIndex(0) : setIndex(index + 1)
    const handlePrevious = () =>
        index === 0 ? setIndex(length) : setIndex(index - 1)

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    shuffleArray(allFile.edges);

    function update() {
        handleNext();

    }
    setTimeout(update, 4000);
    const { node } = allFile.edges[index]
    return (
        <div className="m-auto max-w-7xl w-full mt-12 lg:mt-0 px-6 mb-12 ">
            <GatsbyImage key={node.id} className="bg-zinc-200 dark:bg-zinc-600 banner w-full h-[12rem] rounded-3xl shadow-xl dark:brightness-75" image={node.childImageSharp.gatsbyImageData} 
            alt={node.name.replace(/-/g, ' ').substring(2)} />
        </div>
    )
    }
    return SlideShow();
}

export default Banner

